import waypointsService from '../../../../services/discharge-waypoints.service';
import {
  selectArrivedPageForm,
  selectArrivedPagePhotos,
  selectRequiredPhotosWaypointIds,
  setArrivedPagePhotos,
  setFailedButtonDisabledState,
  setSuccessButtonDisabledState,
  useLazyGetWaypointPhotosQuery,
  useLazyGetWaypointPicturesQuery,
} from '@/features/route';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { useLiveQuery } from 'dexie-react-hooks';
import { photoDB } from '@/database';
import serviceWaypoints from '../../../../services/service-waypoints.service';
import { Waypoint, WaypointType, selectCurrentRouteGroupItem, selectIsCurrentRouteGroupItemOpenedMode } from '@/features/home';
import { socket } from '@/lib/socket';
import { toast } from 'react-toastify';
import { useAppSelector } from '@/store/types';
import { selectIsSyncingEnabled } from '@/store/settings/slice';

export function useArrivedPageUploadedPhotos(arrivedWaypoints: Waypoint[]) {
  const pendingPhotos = useLiveQuery(() => photoDB.photos.toArray());

  const dispatch = useDispatch();
  const isOffline = useOfflineStatus();

  const currentRouteGroupItem = useAppSelector(selectCurrentRouteGroupItem);
  const waypointsPhotos = useAppSelector(selectArrivedPagePhotos);
  const requiredPhotosWaypointIds = useAppSelector(selectRequiredPhotosWaypointIds);
  const isRouteGroupItemOpenedMode = useAppSelector(selectIsCurrentRouteGroupItemOpenedMode);
  const { selectedWaypointIds, services: formServices } = useAppSelector(selectArrivedPageForm) || {};
  const isSyncingEnabled = useAppSelector(selectIsSyncingEnabled);

  const [getWaypointPhotos, { isFetching: isWaypointPhotosFetching }] = useLazyGetWaypointPhotosQuery();
  const [getWaypointPictures, { isFetching: isWaypointPicturesFetching }] = useLazyGetWaypointPicturesQuery();

  const getAndSetWaypointPhotos = useCallback(
    async (waypointIds: number[]) => {
      try {
        const photoLogs = currentRouteGroupItem?.isDischargeSheet
          ? await getWaypointPhotos({ waypointIds }).unwrap()
          : await getWaypointPictures(waypointIds).unwrap();

        if (!photoLogs) {
          return;
        }

        const photoWaypointMap = photoLogs.reduce<Record<string, { filePath: string }[]>>((acc, log) => {
          if (!currentRouteGroupItem?.isDischargeSheet) {
            if (!acc[log.objectServiceId]) {
              acc[log.objectServiceId] = [];
            }

            acc[log.objectServiceId].push({ filePath: log.content });

            return acc;
          }

          if (!acc[log.routeGroupItemWaypointId]) {
            acc[log.routeGroupItemWaypointId] = [];
          }
          acc[log.routeGroupItemWaypointId].push({ filePath: log.stringValue });

          return acc;
        }, {});

        dispatch(setArrivedPagePhotos(photoWaypointMap));
      } catch (error) {
        toast('Error fetching photos', {
          type: 'error',
        });
      }
    },
    [currentRouteGroupItem, dispatch, getWaypointPhotos, getWaypointPictures],
  );

  const reviewSuccessFailedButtonDisableState = () => {
    if (!selectedWaypointIds?.length || (!Object.keys(waypointsPhotos).length && !pendingPhotos?.length)) {
      return;
    }

    if (!currentRouteGroupItem?.isDischargeSheet) {
      if (requiredPhotosWaypointIds.every((waypointId) => pendingPhotos?.some(({ waypointIds }) => waypointIds.includes(waypointId)))) {
        dispatch(setSuccessButtonDisabledState({ isUploaded: true }));
      } else {
        dispatch(setSuccessButtonDisabledState({ isUploaded: false }));
      }

      return;
    }

    if (selectedWaypointIds.every((waypointId) => waypointsPhotos[waypointId]?.length > 0)) {
      dispatch(setFailedButtonDisabledState({ isUploaded: true }));
    } else {
      dispatch(setFailedButtonDisabledState({ isUploaded: false }));
    }

    if (requiredPhotosWaypointIds.every((waypointId) => waypointsPhotos[waypointId]?.length > 0)) {
      dispatch(setSuccessButtonDisabledState({ isUploaded: true }));
    } else {
      dispatch(setSuccessButtonDisabledState({ isUploaded: false }));
    }

    if (requiredPhotosWaypointIds.every((waypointId) => pendingPhotos?.some(({ waypointIds }) => waypointIds.includes(waypointId)))) {
      dispatch(setSuccessButtonDisabledState({ isUploaded: true }));
    }
    if (selectedWaypointIds.every((waypointId) => pendingPhotos?.some(({ waypointIds }) => waypointIds.includes(waypointId)))) {
      dispatch(setFailedButtonDisabledState({ isUploaded: true }));
    }
  };

  useEffect(() => {
    reviewSuccessFailedButtonDisableState();
  }, []);

  useEffect(() => {
    if (!currentRouteGroupItem?.isDischargeSheet) {
      dispatch(setFailedButtonDisabledState({ isRequired: false }));
      dispatch(setSuccessButtonDisabledState({ isRequired: true }));
    }
  }, [currentRouteGroupItem, dispatch]);

  useEffect(() => {
    if (isRouteGroupItemOpenedMode || !arrivedWaypoints.length || isOffline) {
      return;
    }

    const waypointIds = arrivedWaypoints.map(({ id }) => id);

    const interval = setInterval(async () => {
      if (!socket?.connected) {
        getAndSetWaypointPhotos(waypointIds);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [arrivedWaypoints, isOffline, getAndSetWaypointPhotos, isRouteGroupItemOpenedMode]);

  useEffect(() => {
    if (isRouteGroupItemOpenedMode || !arrivedWaypoints.length || isOffline) {
      return;
    }
    const waypointIds = arrivedWaypoints.map(({ id }) => id);
    getAndSetWaypointPhotos(waypointIds);
  }, []);

  useEffect(() => {
    if (!isSyncingEnabled) {
      return;
    }

    socket.on('PhotoUploaded', ({ payload: { waypointIds } }) => {
      if (!waypointIds?.length) {
        toast('No waypoints received from tablet', {
          type: 'error',
        });
        return;
      }
      if (waypointIds.some((waypointId) => arrivedWaypoints.some(({ id }) => id === waypointId))) {
        getAndSetWaypointPhotos(waypointIds);
      }
    });

    return () => {
      socket.off('PhotoUploaded');
    };
  }, [getAndSetWaypointPhotos, isSyncingEnabled, dispatch, arrivedWaypoints]);

  useEffect(() => {
    if (!arrivedWaypoints.length || !selectedWaypointIds?.length) {
      return;
    }

    const filteredWaypoints = arrivedWaypoints.filter((waypoint) => selectedWaypointIds.includes(waypoint.id));

    let isObjectItemPhotoRequired = false;
    let isServicePhotoRequired = false;
    const requiringPhotosWaypointIds: number[] = [];

    filteredWaypoints.forEach((waypoint) => {
      if (waypoint.type === WaypointType.Service) {
        isObjectItemPhotoRequired = true;
        requiringPhotosWaypointIds.push(waypoint.id);
        return;
      }

      if (waypoint.objectItem.objectItemPhotoRequired) {
        isObjectItemPhotoRequired = true;
        requiringPhotosWaypointIds.push(waypoint.id);
      }

      const photoRequiredServices =
        waypoint.type === WaypointType.Discharge ? waypoint.services.filter((service) => service.isPhotoRequired) : [];

      photoRequiredServices.forEach(({ id }) => {
        if (formServices[waypoint.id][id].value > 0) {
          isServicePhotoRequired = true;
          requiringPhotosWaypointIds.push(waypoint.id);
        }
      });
    });

    dispatch(
      setSuccessButtonDisabledState({
        isRequired: isObjectItemPhotoRequired || isServicePhotoRequired,
        waypointIds: Array.from(new Set(requiringPhotosWaypointIds)),
      }),
    );
  }, [arrivedWaypoints, selectedWaypointIds, formServices, dispatch]);

  useEffect(() => {
    reviewSuccessFailedButtonDisableState();
  }, [selectedWaypointIds, requiredPhotosWaypointIds, waypointsPhotos, pendingPhotos]);

  return {
    getAndSetWaypointPhotos,
  };
}
