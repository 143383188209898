import Dexie from 'dexie';
import { Coordinates } from '../types/common';

export enum CoordinateUpdateEntity {
  ServiceWaypoint = 'serviceWaypoint',
  DischargeWaypoint = 'dischargeWaypoint',
}

interface CoordinateUpdateWaypointBodyRequest {
  entity: CoordinateUpdateEntity.DischargeWaypoint;
  waypointIds: number[];
}

export interface CoordinateUpdateObjectItemBodyRequest {
  entity: CoordinateUpdateEntity.ServiceWaypoint;
  serviceIds: number[];
}

export interface CoordinateUpdateRequest {
  id?: number;
  routeGroupItemId: number;
  body: Coordinates & (CoordinateUpdateWaypointBodyRequest | CoordinateUpdateObjectItemBodyRequest);
}

export class CoordinateUpdateRequestsDb extends Dexie {
  requests: Dexie.Table<CoordinateUpdateRequest, number>;

  constructor() {
    super('coordinateUpdateRequestsDb');

    this.version(1).stores({
      requests: '++id, body',
    });
  }
}

export const coordinateUpdateRequestsDb = new CoordinateUpdateRequestsDb();
