import React from 'react';
import { conditionalClassNames } from '@/helpers/dom';
import Button from '@/components/elements/Button';
import EyeIcon from '@/components/icons/EyeIcon';
import ArrivedIcon from '@/components/icons/ArrivedIcon';
import { getGarbageIconLink } from '@/helpers/garbage';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsCurrentRouteGroupItemOpenedMode, Waypoint } from '@/features/home';
import { useCommonItemMaps } from '@/hooks/useCommonItemMaps';
import MapIcon from '@/components/icons/MapIcon';
import { useUploadPlanImages } from '../../hooks/useUploadPlanImages';
import { cn } from '@/utils/cn';

interface WaypointListItemTemplateProps {
  onArrivedButtonClick: () => void;
  isArrivedButtonDisabled?: boolean;
  isSelected: boolean;
  isDone: boolean;
  isFailed: boolean;
  isPartiallyDone: boolean;
  isOnMainScreen?: boolean;
  showContainers?: boolean;
  address: string;
  ownerName: string;
  notes: string;
  uploadPlan?: string[];
  timeWindows?: string;
  waypointsGroup: Waypoint[];
  addressPrefixSlot?: React.ReactNode;
  isDisabled?: boolean;
}

const WaypointListItemTemplate = ({
  waypointsGroup,
  isSelected,
  isFailed,
  isDone,
  isPartiallyDone,
  isOnMainScreen = false,
  isArrivedButtonDisabled = false,
  address,
  notes,
  timeWindows,
  onArrivedButtonClick,
  showContainers,
  ownerName,
  uploadPlan = [],
  addressPrefixSlot = null,
  isDisabled = false,
}: WaypointListItemTemplateProps) => {
  const { t } = useTranslation();
  const { itemTypesMap } = useCommonItemMaps();
  const { setUploadPlanImages, toggleUploadPlanImagesModal } = useUploadPlanImages();

  const isRouteGroupItemOpenedMode = useSelector(selectIsCurrentRouteGroupItemOpenedMode);

  const containers = waypointsGroup.reduce<Record<string, Record<string, number>>>((acc, waypoint) => {
    const { itemTypeId, garbageId } = waypoint.objectItem;
    const { capacity, name } = itemTypesMap?.[itemTypeId] || {};

    if (!acc[garbageId]) {
      acc[garbageId] = {};
    }

    if (capacity > 0) {
      if (!acc[garbageId][capacity]) {
        acc[garbageId][capacity] = 0;
      }

      acc[garbageId][capacity] += 1;

      return acc;
    }

    if (!acc[garbageId][name]) {
      acc[garbageId][name] = 0;
    }

    acc[garbageId][name] += 1;

    return acc;
  }, {});

  return (
    <div
      className={cn({
        'tablet-page__waypoints-list__group': true,
        'tablet-page__waypoints-list__group__selected': isSelected,
        'tablet-page__waypoints-list__group__done': isDone,
        'tablet-page__waypoints-list__group__failed': isFailed,
        'tablet-page__waypoints-list__group__partially-done': isPartiallyDone,
        'select-none cursor-default pointer-events-none': isDisabled,
      })}
      data-waypoint-ids={waypointsGroup.map(({ id }) => id)}
    >
      <div className="relative">
        <div className={cn({ 'bg-gray-200 dark:bg-gray-800 opacity-50 h-full w-full absolute inset-0 z-10': isDisabled })}></div>
        <h3 className={'flex items-center gap-1.5'}>
          {addressPrefixSlot && addressPrefixSlot}
          {address}
        </h3>
        {timeWindows && <div className={'bg-gray-100 text-red-600 font-semibold p-1 w-fit rounded'}>{timeWindows}</div>}
        {isSelected && (
          <>
            <div className="tablet-page__waypoints-list__group__owner">{ownerName}</div>
            {isOnMainScreen && (
              <div className="tablet-page__waypoints-list__group__actions">
                <Button
                  text={isRouteGroupItemOpenedMode ? t('open', { ns: 'common' }) : t('navbar.arrived', { ns: 'tabletPage' })}
                  color={'success'}
                  icon={isRouteGroupItemOpenedMode ? <EyeIcon size={'sm'} /> : <ArrivedIcon size={'sm'} />}
                  size={'md'}
                  disabled={isArrivedButtonDisabled}
                  onClick={!isDisabled ? () => onArrivedButtonClick() : null}
                  wide
                />
              </div>
            )}
            {notes && (
              <div className="bg-gray-100 text-red-600 font-semibold p-1 py-1.5 w-fit rounded">
                {notes.split('\n').map((note) => (
                  <div key={note}>{note}</div>
                ))}
              </div>
            )}
            {uploadPlan?.length > 0 && (
              <div>
                <Button
                  size={'sm'}
                  wide
                  icon={<MapIcon size={'sm'} />}
                  text={t('drivingDirection')}
                  onClick={(event) => {
                    event.stopPropagation();
                    setUploadPlanImages(uploadPlan);
                    toggleUploadPlanImagesModal();
                  }}
                />
              </div>
            )}
          </>
        )}
        {(isSelected || showContainers) && (
          <div className="tablet-page__waypoints-list__group-list">
            {Object.entries(containers).map(([garbageId, container]) =>
              Object.entries(container).map(([capacity, containersAmount]) => (
                <div key={`${garbageId}-${capacity}`} className="tablet-page__waypoints-list__group-item">
                  <img src={getGarbageIconLink(garbageId)} alt="" />
                  <div className="tablet-page__waypoints-list__group__item__title">
                    {isNaN(Number(capacity)) ? (
                      capacity
                    ) : (
                      <>
                        {capacity}
                        <small>m3</small>
                      </>
                    )}
                    {containersAmount > 1 && <>&nbsp;x&nbsp;{containersAmount}</>}
                  </div>
                </div>
              )),
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default WaypointListItemTemplate;
