import React from 'react';
import { useTranslation } from 'react-i18next';
import { unifiedString } from '@/helpers/strings';
import { useObjectAddress } from '@/hooks/useObjectAddress';
import { Waypoint } from '@/features/home';
import { useSelector } from 'react-redux';
import Button from '@/components/elements/Button';
import MapIcon from '@/components/icons/MapIcon';
import { useUploadPlanImages } from '../../route/hooks/useUploadPlanImages';
import { selectMode } from '@/store/settings/slice';
import { AppMode } from '@/types/common';

interface WaypointInfoProps {
  waypoint: Waypoint | null;
  waypointsAmount?: number;
}

export const WaypointInfoForm = ({ waypoint, waypointsAmount = 1 }: WaypointInfoProps) => {
  const { t } = useTranslation();
  const { owner, contract, object } = waypoint ?? {};
  const fullAddress = useObjectAddress(object);

  const appMode = useSelector(selectMode);

  const { setUploadPlanImages, toggleUploadPlanImagesModal } = useUploadPlanImages();

  return (
    <>
      <div className="photo__waypoint-info">
        <div className="flex flex-col photo__waypoint-title md:flex-row md:items-center md:gap-2">
          <span>{fullAddress}</span>
          {object?.serviceStartTime && object?.serviceEndTime && (
            <span className={'flex items-center gap-2 text-red-600 font-semibold px-1 bg-gray-100 rounded w-fit'}>
              {`${object.serviceStartTime.slice(0, 5)}-${object.serviceEndTime.slice(0, 5)}`}
            </span>
          )}
        </div>
        <div className="photo__waypoint-info-main">
          <div>
            <span>{t('owner')}:</span>
            <span>{owner?.companyName || unifiedString([owner?.firstname, owner?.surname])}</span>
          </div>
          <div>
            <span>{t('phoneLabel')}:</span>
            <span>{waypoint?.owner?.phone || waypoint?.owner?.phone2}</span>
          </div>
          <div>
            <span>{t('contractNumber')}:</span>
            <span> {contract?.contractNumber}</span>
          </div>
          <div>
            <span>{t('containers')}:</span>
            <span> {waypointsAmount}</span>
          </div>
          {object?.notes && (
            <div>
              <span>{t('notes')}:</span>
              <span>{object?.notes}</span>
            </div>
          )}
          {object?.uploadPlan?.length && appMode !== AppMode.Basic && (
            <div className={'pt-1 md:pt-0 md:w-1/2 md:ml-auto'}>
              <Button
                size={'sm'}
                icon={<MapIcon size={'sm'} />}
                text={t('drivingDirection')}
                onClick={(event) => {
                  event.stopPropagation();
                  setUploadPlanImages(object.uploadPlan);
                  toggleUploadPlanImagesModal();
                }}
                wide
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WaypointInfoForm;
